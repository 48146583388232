// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:25+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            d44116c377d808135f9c39a62c096266
//    Signature:      eyJpdiI6ImNmS0lWR0VrN2lVdk5BY3A5ZzVKakE9PSIsInZhbHVlIjoiRXNLeFpLYVZLaGM0NWpNa2V6OVRXdU01cTVrb2FyWEpaZHAxRUtXQzdYdEQwNnlINTVjTTQ1NVBQRHRtazVGS3dMd0syQk10TjJIYzFyRWI4QjRBbEVEU285RUVYTVwvZzhEUEptTDM5TytteUJTUDJiQWlKdGkzRCt3VnJQQjJ3bUZjdDdhVDVrcGRIcHJPR1FiZ0FkRHJnT3piVFVQV1BuMzJLQlpibGF0bUlhaFBqSVFuN3BSRHpyb21qRG5mdE9IMCszZFZNV291UnRSWFJTUmxWVFwvQUd1eUZXK0hZMDNYYitPMlkzQlA1ZzBDXC9Ga2E5NVlLNzZlS1FNT0JWZW9QRVN1bzNBNEZETlluc3VoYmMwNm1GdGFsQ0I5eUJaRHdleHNhS1hRbXhuZmZ2OHptKzdlRGIxaVM2MndXakNtR1c5c3l6Wm5WUktiRVdTU3RhQWVMRExjYUNnNWJ6Uk9VTjQ0cWd3RmdrWFVHaVJzblk5RmhVREJZczBoblNqbmFxWVpYVVhxYmZReEF2bXJJWGpEQT09IiwibWFjIjoiODM4NDRhNzQ0ZTEzMzEyNzE5MTAyOGRhNzZjZGE3YjY4YWUzOGMyMDk2NmRkOWNkOGMwZmEzMjI5YjI1ZWJiMiJ9
if (!customElements.get('product-model')) {
  customElements.define('product-model', class ProductModel extends DeferredMedia {
    constructor() {
      super();
    }

    loadContent() {
      super.loadContent();

      Shopify.loadFeatures([
        {
          name: 'model-viewer-ui',
          version: '1.0',
          onLoad: this.setupModelViewerUI.bind(this),
        },
      ]);
    }

    setupModelViewerUI(errors) {
      if (errors) return;

      this.modelViewerUI = new Shopify.ModelViewerUI(this.querySelector('model-viewer'));
    }
  });
}

window.ProductModel = {
  loadShopifyXR() {
    Shopify.loadFeatures([
      {
        name: 'shopify-xr',
        version: '1.0',
        onLoad: this.setupShopifyXR.bind(this),
      },
    ]);
  },

  setupShopifyXR(errors) {
    if (errors) return;

    if (!window.ShopifyXR) {
      document.addEventListener('shopify_xr_initialized', () =>
        this.setupShopifyXR()
      );
      return;
    }

    document.querySelectorAll('[id^="ProductJSON-"]').forEach((modelJSON) => {
      window.ShopifyXR.addModels(JSON.parse(modelJSON.textContent));
      modelJSON.remove();
    });
    window.ShopifyXR.setupXRElements();
  },
};

window.addEventListener('DOMContentLoaded', () => {
  if (window.ProductModel) window.ProductModel.loadShopifyXR();
});
